<template>
  <span>{{ stock }}</span>
</template>
<script>
export default {
  props: { sku: { type: String, required: true } },
  data () {
    return {
      stock: null
    }
  },
  mounted () {
    this.getStock()
  },
  methods: {
    getStock: async function () {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Basic YXBpdXNlcjoxMjM0NTY=')

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }

      fetch('http://localhost:8080/api/stock/' + this.sku, requestOptions)
        .then(response => {
          return response.json()
        })
        .then(result => {
          this.stock = result.stock
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('error', error)
        })
    }
  }
}
</script>
