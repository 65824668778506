import Vue from 'vue'

// Lib imports
import axios from 'axios'
import store from '@/store'

axios.defaults.withCredentials = true

axios.interceptors.request.use(
  config => {
    config.baseURL = process.env.VUE_APP_ROOT_API
    // config.withCredentials = true; // 允许携带token ,这个是解决跨域产生的相关问题
    config.timeout = 6000
    config.headers['Content-Type'] = 'application/json'

    const keycloak = store.state.app.keycloak

    if (keycloak.idToken) {
      // 附加token
      config.headers = {
        Authorization: 'bearer ' + keycloak.idToken
      }
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axios

export default axios
