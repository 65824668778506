<template>
  <v-autocomplete
    :items="items"
    :placeholder="data.name"
  />
</template>
<script>
export default {
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    items: function () {
      return this.data.values.map(item => {
        return {
          text: item.value,
          value: item.vid
        }
      })
    }
  }
}
</script>
