<template>
  <v-dialog
    v-model="dialog"
    width="800"
  >
    <template #activator="{ on: dialog }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            text
            small
            color="primary"
            flat
            v-on="{ ...tooltip, ...dialog }"
            @click="viewTrackingDetail(trackingCode)"
          >
            {{ trackingCode }}
          </v-btn>
        </template>
        <span>查看物流信息</span>
      </v-tooltip>

      <!-- <v-btn color="red lighten-2" dark v-on="on">Click Me</v-btn> -->
    </template>
    <material-card
      :text="'快递单号:' + trackingCode"
      color="green"
      offset="6"
      title="物流详情"
      hide-actions
    >
      <v-container py-0>
        <v-layout wrap>
          <v-flex md12>
            <h3>物流信息</h3>
            <ul>
              <li
                v-for="(item, index) in records"
                :key="index"
              >
                {{ item.stateDescription }}
                <br>
                &nbsp;&nbsp;{{ item.scanDate }}
              </li>
            </ul>

            <!-- <v-list-item v-for="(item, index) in records" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ item.scanDate }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.stateDescription }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>-->
          </v-flex>
        </v-layout>
      </v-container>
    </material-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    trackingCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      records: {}
    }
  },
  methods: {
    viewTrackingDetail (trackingCode) {
      return this.$http.get(`/tracking/${trackingCode}`).then((response) => {
        this.records = response.data
      })
    }
  }
}
</script>
