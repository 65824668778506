<template>
  <v-select
    :value="value"
    :items="status"
    placeholder="状态"
    @input="emitValue"
  />
</template>
<script>
export default {
  props: { value: { type: String, required: false, default: '待发货' } },
  data: () => ({
    status: [
      '已发货',
      '待发货',
      '已完成',
      '已关闭',
      '待付款'
    ]
  }),
  methods: {
    emitValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
