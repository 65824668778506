<template>
  <material-card
    color="orange"
    title="Best sales"
    text="总销量排行"
  >
    <v-data-table
      id="best_sales"
      :headers="headers"
      :items="items"
    >
      <template
        slot="headerCell"
        slot-scope="{ header }"
      >
        <span
          class="font-weight-light text-warning text--darken-3"
          v-text="header.text"
        />
      </template>
    </v-data-table>
  </material-card>
</template>
<script>
export default {
  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'SKU',
          value: 'product_sku'
        },
        {
          sortable: false,
          text: '名称',
          value: 'product_name',
          width: '500px'
        },
        {
          sortable: false,
          text: '销量',
          value: 'total',
          align: 'right'
        }
      ],
      items: []
    }
  },
  mounted () {
    this.getBestSales()
  },
  methods: {
    getBestSales: async function () {
      this.$http.get('/orders/bestsales/report').then(response => {
        this.items = response.data
      })
    }
  }
}
</script>
