<template>
  <v-dialog
    v-model="dialog"
    width="800"
  >
    <template #activator="{ on:dialog }">
      <v-tooltip bottom>
        <template #activator="{ on:tooltip }">
          <v-icon
            small
            v-on="{ ...tooltip, ...dialog }"
            @click="viewOrderDetail(id)"
          >
            mdi-arrow-down-drop-circle-outline
          </v-icon>
        </template>
        <span>查看订单详情</span>
      </v-tooltip>

      <!-- <v-btn color="red lighten-2" dark v-on="on">Click Me</v-btn> -->
    </template>
    <material-card
      color="green"
      offset="6"
      title="订单详情"
      :text="'订单号:' + id"
      hide-actions
    >
      <v-container py-0>
        <v-layout wrap>
          <v-flex md6>
            <h3>订单信息</h3>
            <ul>
              <li>
                渠&nbsp;&nbsp;&nbsp;&nbsp;道：
                <order-channel :id="order.channelId" />
              </li>
              <li>订单号： {{ order.externalOrderId }}</li>
              <li>
                总&nbsp;&nbsp;&nbsp;&nbsp;额：
                <common-price :price="order.total" />
              </li>
              <li>
                状&nbsp;&nbsp;&nbsp;&nbsp;态：
                <order-status :status="order.status" />
              </li>
            </ul>
            <p>
              <br>
            </p>
          </v-flex>
          <v-flex md6>
            <h3>客户信息</h3>
            <ul>
              <li>姓名： {{ order.customerName }}</li>
              <li>电话： {{ order.customerPhone }}</li>
              <li>地址： {{ order.customerAddress }}</li>
            </ul>
          </v-flex>
        </v-layout>

        <h3>产品信息</h3>
        <v-data-table
          :headers="headers"
          :items="order.orderItems"
          hide-default-footer
        >
          <template
            slot="item"
            slot-scope="{ item }"
          >
            <tr>
              <td>
                <v-img
                  :src="item.img"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  width="80"
                />
              </td>
              <td>{{ item.productSku }}</td>
              <td>{{ item.productName }}</td>
              <td>{{ item.productCount }}</td>
              <td>
                <common-price :price="item.productPrice" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </material-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      order: {},
      headers: [
        {
          sortable: false,
          text: ''
        },
        {
          sortable: false,
          text: ''
        },
        {
          sortable: false,
          text: ''
        },
        {
          sortable: false,
          text: '数量'
        },
        {
          sortable: false,
          text: '价格'
        }
      ]
    }
  },
  methods: {
    viewOrderDetail (id) {
      return this.$http.get(`/orders/${id}`).then(response => {
        this.order = response.data
      })
    }
  }
}
</script>
