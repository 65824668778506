<template>
  <span>{{ channelName }}</span>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    channelName: function () {
      return ['微店', '拼多多'][this.id - 1]
    }
  }
}
</script>
