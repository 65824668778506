<template>
  <material-chart-card
    :data="timePeriodChart.data"
    :options="timePeriodChart.options"
    color="green"
    type="Line"
  />
</template>

<script>
export default {
  props: ['id', 'type'],
  data () {
    return {
      timePeriodChart: {
        data: {
          labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
          series: [[12, 17, 7, 17, 23, 18, 38]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),

          divisor: 5,
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      }
    }
  },
  watch: {
    id: function (newVal, oldVal) {
      this.getReport()
    }
  },
  mounted () {
    this.getReport()
  },
  methods: {
    getReport: async function () {
      this.$http
        .get('/orders/timeperiod/report?id=' + this.id + '&type=' + this.type)
        .then((response) => {
          // 月销售额报表
          const data = response.data

          const totalSales = data.map((row) => row.c)
          const average = data.reduce((a, b) => a + b.c, 0) / data.length
          this.timePeriodChart.options.high = Math.max(...totalSales)

          this.timePeriodChart.data = {
            labels: data.map((row) => row.order_hour),
            series: [data.map((row) => row.c), data.map((row) => average)]
          }
        })
    }
  }
}
</script>

<style></style>
