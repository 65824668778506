<template>
  <v-footer
    id="core-footer"
    absolute
    height="82"
  >
    <div class="footer-items">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="tertiary--text footer-links"
          :target="link.target ? link.target : '_self'"
        >{{ link.name }}</a>
      </span>
    </div>
    <v-spacer />
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        href="https://www.creative-tim.com/"
        target="_blank"
      >Creative Tim</a>, made with
      <v-icon
        color="tertiary"
        size="17"
      >mdi-heart</v-icon>for a better web
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      { name: 'Home', Link: '/dashboard' },
      {
        name: '中通物流跟踪',
        Link: 'https://www.zto.com/express/expressCheck.html',
        target: '_blank'
      },
      {
        name: '中通时效查询',
        Link: 'https://www.zto.com/express/expressPrice.html',
        target: '_blank'
      }
      // { name: 'Blog', Link: 'https://blog.creative-tim.com' }
    ]
  })
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
