<template>
  <v-app>
    <component :is="layout" :keycloak="keycloak" />
  </v-app>
</template>

<script>
const defaultLayout = 'default'

export default {
  props: ['keycloak'],
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
