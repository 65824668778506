<template>
  <div>
    <h1>properties</h1>
    <div
      v-for="property in properties"
      :key="property.id"
    >
      <component
        :is="'pdd-property-control-' + property.control_type"
        v-if="property.control_type <= 1"
        :data="property"
      />

      <!-- 控件类型
        （0-可输入、1-可勾选、3-可输入又可勾选、5-单项时间选择器-年月日、
        6-双项时间选择器-年月日、7-单项时间选择器-年月、8-双项时间选择器-年月）
        9-调色盘、10-尺码选择器、11-输入数值范围、12-输入数值乘积-2维、
        13-输入数值乘积-3维 -->
      <div v-if="property.control_type > 1">
        {{ property.name }}
        {{ property.control_type }}
      </div>
    </div>
  </div>
</template>
<script>

import pdd from '../../api/pdd'
export default {
  props: {
    catId: { type: Number, required: false, default: null }
  },
  data: () => ({
    properties: []
  }),
  watch: {
    catId: function (newValue, oldValue) {
      if (newValue != null) {
        this.getProperties(newValue)
      }
    }
  },
  mounted () {
    this.getProperties(this.catId)
  },
  methods: {
    getProperties: function (catId) {
      if (catId) {
        pdd.getCategoryProperties(catId).then(data => {
          console.log(data)
          this.properties = data.properties
        })
      }
    }
  }
}
</script>
