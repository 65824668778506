<template>
  <v-select
    :items="products"
    placeholder="产品选择"
    @change="change"
  />
</template>
<script>
export default {
  data () {
    return {
      products: [
        {
          text: '插座转换器',
          value: 42858368352
        },
        {
          text: '大功率一转二',
          value: 51901421054
        },
        {
          text: '大功率有线',
          value: 51869963875
        },
        {
          text: 'B7插座',
          value: 43559838032
        },
        {
          text: '胶带',
          value: 118885872175
        }
      ]
    }
  },
  methods: {
    change: function (value) {
      this.$emit('change', value)
    }
  }
}
</script>
