<template>
  <span :style="style">{{ status }}</span>
</template>
<script>
export default {
  props: { status: { type: String, required: true } },
  computed: {
    style: function () {
      const index = ['待发货'].indexOf(this.status)
      if (index !== -1) {
        return {
          color: 'red'
        }
      }

      return null
    }
  }
}
</script>
