<template>
  <span>{{ formattedPrice }}</span>
</template>
<script>
export default {
  props: {
    price: {
      type: Number,
      required: true
    }
  },
  computed: {
    formattedPrice: function () {
      if (!this.price) {
        return ''
      }

      return (this.price / 100).toFixed(2)
    }
  }
}
</script>
