<template>
  <span>{{ formatDate }}</span>
</template>
<script>
import moment from 'moment'
export default {
  props: ['date'],
  computed: {
    formatDate: function () {
      if (!this.date) {
        return ''
      }

      return moment(this.date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
