import axios from '../plugins/axios'

class PddClient {
  /**
   * 店铺可卖的商品类目
   *
   * @param Number parent 父级id
   */
  async getAuthCategories (parent) {
    const res = await axios.get(`/pdd/category/${parent}/categories/authorization`)
    return res.data
  }

  /**
   * 类目属性
   * @param Number catId 类目的ID
   */
  async getCategoryProperties (catID) {
    const res = await axios.get(`/pdd/category/${catID}/properties`)
    return res.data
  }

  /**
   * 获取运费模板
   */
  async getShippingTemplates () {
    const res = await axios.get('/pdd/shipping/templates')
    return res.data
  }
}

export default new PddClient()
