<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        xs12
        md10
      >
        <material-card
          color="green"
          title="添加产品"
          text="添加一个新的产品"
        >
          <v-form
            ref="form"
            lazy-validation
          >
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="product.sku"
                    :rules="[v=>!!v || '请填写SKU']"
                    required
                    class="purple-input"
                    label="SKU"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md8
                >
                  <v-text-field
                    v-model="product.name"
                    class="purple-input"
                    label="产品名称"
                  />
                </v-flex>

                <!-- <v-flex xs12 md4>
                  <v-text-field
                    v-model="product.cost"
                    class="purple-input"
                    label="进价"
                    type="number"
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="product.limitPrice"
                    :rules="numberRules('限价')"
                    class="purple-input"
                    label="限价"
                    type="number"
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="product.weidianPrice"
                    :rules="numberRules('微店价格')"
                    label="微店价格"
                    class="purple-input"
                    type="number"
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    :rules="[urlRule]"
                    v-model="product.tmallUrl"
                    label="天猫URL"
                    class="purple-input"
                  />
                </v-flex>-->

                <v-row>
                  <v-col md="12">
                    <v-toolbar
                      id="add_product_div"
                      dense
                      flat
                    >
                      <v-toolbar-title>变种</v-toolbar-title>
                      <div class="flex-grow-1" />
                      <v-btn
                        icon
                        @click="addVariant"
                      >
                        <v-icon color="green">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-toolbar>
                  </v-col>
                </v-row>

                <v-flex
                  v-for="(item, id) in variants"
                  :key="id"
                  xs12
                >
                  <product-variant
                    :index="id"
                    @remove-variant="removeVariant"
                  />
                </v-flex>

                <v-flex
                  xs12
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="success"
                    @click="saveProduct"
                  >
                    保存
                  </v-btn>&nbsp;&nbsp;
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="default"
                    @click="close"
                  >
                    取消
                  </v-btn>&nbsp;&nbsp;
                  <!-- <v-btn class="mx-0 font-weight-light" color="success" @click="saveProduct">提交并继续添加</v-btn> -->
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-snackbar
        v-model="snackbar"
        color="secondary"
        top
      >
        {{ errorMessage }}
        <v-btn
          color="warning"
          @click="snackbar = false"
        >
          关闭
        </v-btn>
      </v-snackbar>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    errorMessage: '',
    dialog: false,
    variants: [{}], // 子产品列表
    product: {}
  }),
  methods: {
    /**
     * 添加变种
     */
    addVariant: function () {
      this.variants.push({})
    },
    removeVariant: function (index) {
      console.log(this.variants)
      console.log('removing', index)
      this.variants.splice(index, 1)
      console.log(this.variants)
    },
    close: function () {
      this.$emit('close-dialog')
    },
    saveProduct: function () {
      if (this.$refs.form.validate()) {
        this.$http
          .post(
            '/v2/products',
            this.normalizeProduct(this.product, this.variants)
          )
          .then(response => {
            console.log(response)
            this.close()
          })
          .catch(err => {
            this.snackbar = true
            this.errorMessage =
              'Error:' + err.response.status + ' ' + err.response.data.error
          })
      }
    },
    normalizeProduct: function (product, variants) {
      const productToSubmit = Object.assign({}, product)
      productToSubmit.variants = Object.assign([], variants)

      const types = ['cost', 'limitPrice', 'weidianPrice', 'jdPrice', 'tmallPrice']

      types.forEach(type => {
        productToSubmit.variants.forEach(variant => {
          if (variant[type]) {
            variant[type] *= 100
          }
        })
      })

      return productToSubmit
    },
    numberRules: function (field) {
      const rules = []
      // {
      //   const rule = v => !!v || `请填写${field}`;
      //   rules.push(rule);
      // }

      // {
      //   const rule = v =>
      //     (!!v && v.match(/^[0-9]+(\.[0-9]{1,2})?$/)) ||
      //     `${field}格式错误,只能最多两位小数`;
      //   rules.push(rule);
      // }

      return rules
    }
  }
}
</script>
<style scoped>
#add_product_div >>> .v-toolbar__content {
  margin-left: 1px;
  padding-left: 12px;
}
</style>
