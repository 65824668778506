<template>
  <v-text-field :placeholder="data.name" />
</template>
<script>
export default {
  props: {
    data: { type: Object, required: true }
  }
}
</script>
