/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  // { path: '/login', view: 'Login', meta: { layout: 'no-container' } },
  {
    path: '/dashboard',
    // Relative to /src/views
    view: 'Dashboard',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report',
    // Relative to /src/views
    view: 'Report',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/test',
    // Relative to /src/views
    view: 'Test',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    view: 'UserProfile',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table-list',
    name: 'Table List',
    view: 'TableList',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/typography',
    view: 'Typography',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/icons',
    view: 'Icons',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/maps',
    view: 'Maps',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notifications',
    view: 'Notifications',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/upgrade',
    name: 'Upgrade to PRO',
    view: 'Upgrade',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: '产品管理',
    view: 'Products',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/add',
    name: '添加产品',
    view: 'AddProduct',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/:id',
    name: '编辑产品',
    view: 'AddProduct',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: '订单管理',
    view: 'Orders',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/warehouse',
    name: '库存管理',
    view: 'Warehouse',
    meta: {
      requiresAuth: true
    }
  }
]
