<template>
  <v-autocomplete
    v-if="parent !== null"
    :value="value"
    :items="items"
    @input="emitValue"
  />
</template>
<script>
import pddClient from '../../api/pdd'

export default {
  props: {
    value: { type: Number, required: false, default: null },
    parent: { type: Number, required: false, default: null }
  },
  data: () => ({
    items: []
  }),
  watch: {
    parent: function (newVal, oldVal) {
      this.getCategories(newVal)
    }
  },
  mounted () {
    this.getCategories(this.parent)
  },
  methods: {
    getCategories: async function (parent = 0) {
      if (parent === null) {
        this.items = []
        return
      }

      pddClient.getAuthCategories(parent).then(data => {
        this.items = data.map(category => {
          return {
            text: category.cat_name,
            value: category.cat_id
          }
        })

        this.$emit('input', null)
      })
    },
    emitValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
