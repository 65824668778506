<template>
  <material-card
    color="green"
    title="Best sales"
    text="7天销量"
  >
    <v-data-table
      id="best_sales"
      :headers="headers"
      :items="items"
    >
      <template
        slot="headerCell"
        slot-scope="{ header }"
      >
        <span
          class="font-weight-light text-warning text--darken-3"
          v-text="header.text"
        />
      </template>
      <!-- <template #item.stock="{ item }">
        <span :style="{ color: item.stock < item.total ? 'red' : 'green'}">{{ item.stock }}</span>
      </template> -->
    </v-data-table>
  </material-card>
</template>
<script>
export default {
  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'SKU',
          value: 'product_sku'
        },
        {
          sortable: false,
          text: '名称',
          value: 'product_name',
          width: '500px'
        },
        {
          sortable: false,
          text: '销量',
          value: 'total',
          align: 'right'
        },
        {
          sortable: false,
          text: '库存',
          value: 'stock',
          align: 'right'
        }
      ],
      items: []
    }
  },
  mounted () {
    this.getBestSales()
  },
  methods: {
    getBestSales: async function () {
      this.$http.get('/orders/bestsales/report?type=2').then(response => {
        this.items = response.data
      })
    }
  }
}

</script>
