<template>
  <v-main>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <core-footer v-if="!(['Maps', 'Login'].find(ele => ele == $route.name))" />
  </v-main>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: '鲸牛电商ERP系统'
    }
  }
}
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
