<template>
  <v-select
    :items="products"
    placeholder="时间段"
    @change="change"
  />
</template>
<script>
export default {
  data () {
    return {
      products: [
        {
          text: '1年',
          value: 'year'
        },
        {
          text: '半年',
          value: 'halfyear'
        },
        {
          text: '季度',
          value: 'quauter'
        },
        {
          text: '月',
          value: 'month'
        },
        {
          text: '周',
          value: 'week'
        },
        {
          text: '日',
          value: 'day'
        }
      ]
    }
  },
  methods: {
    change: function (value) {
      this.$emit('change', value)
    }
  }
}
</script>
