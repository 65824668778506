/*!

=========================================================
* Vuetify Material Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vuetify-material-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Plugins
import './plugins'
import vuetify from '@/plugins/vuetify'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

import VueLogger from 'vuejs-logger'
import Keycloak from 'keycloak-js'

import Default from './layouts/Default.vue'
import NoContainer from './layouts/NoContainer.vue'

Vue.component('DefaultLayout', Default)
Vue.component('NoContainerLayout', NoContainer)

Vue.use(VueLogger)

// Sync store with router
sync(store, router)

Vue.config.productionTip = false

const initOptions = {
  url: 'https://auth.whaleniu.cn', realm: 'ERP', clientId: process.env.VUE_APP_KEYClOAK_CLIENT_ID, onLoad: 'login-required'
}

const keycloak = new Keycloak(initOptions)

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload()
  } else {
    Vue.$log.info('Authenticated')

    store.state.app.authenticated = true
    store.state.app.keycloak = keycloak

    /* eslint-disable no-new */
    new Vue({
      vuetify,
      i18n,
      router,
      store,
      render: h => h(App, { props: { keycloak: keycloak } })
    }).$mount('#app')
  }

  // Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token refreshed' + refreshed)
      } else {
        Vue.$log.warn('Token not refreshed, valid for ' +
        Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
        ' seconds')
      }
    }).catch(() => {
      Vue.$log.error('Failed to refresh token')
    })
  }, 6000)
}).catch((e) => {
  Vue.$log.error('Authenticated Failed')
})
