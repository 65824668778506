<template>
  <v-app>
    <core-filter />

    <core-toolbar />

    <core-drawer :keycloak="keycloak" />

    <core-view />
  </v-app>
</template>

<script>
export default {
  props: {
    keycloak: { type: Object, default: null }
  }
}
</script>

<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
