<template>
  <span>{{ profit }}</span>
</template>
<script>
export default {
  props: ['item', 'field'],
  data () {
    return {
      fee: 0.006,
      rate: (10 / 11).toFixed(4),
      shipping: 4
    }
  },
  computed: {
    profit: function () {
      if (this.item.cost === null) {
        return ''
      }
      // 进价
      const cost = parseFloat(this.item.cost)
      // 成本
      const calculatedCost = (cost * this.rate).toFixed(2)
      // 10送一
      return (
        this.item[this.field] * (1 - this.fee) -
        calculatedCost -
        this.shipping
      ).toFixed(2)
    }
  }
}
</script>
