<template>
  <v-autocomplete
    placeholder="运费模板"
    :items="templates"
  />
</template>
<script>

import pdd from '../../api/pdd'

export default {
  data: () => ({
    templates: []
  }),
  mounted () {
    this.getShippingTemplates()
  },
  methods: {
    getShippingTemplates: function () {
      pdd.getShippingTemplates().then(data => {
        console.log(data)
        this.templates = data.map(template => ({
          text: template.template_name,
          value: template.template_id
        }))
      })
    }
  }
}
</script>
