<template>
  <v-row>
    <v-col
      xs="12"
      md="2"
    >
      <v-text-field
        v-model="item.sku"
        label="变种SKU"
        class="purple-input"
      />
    </v-col>
    <v-col
      xs="12"
      md="2"
    >
      <v-text-field
        v-model="item.property"
        label="变种属性"
        class="purple-input"
      />
    </v-col>
    <v-col
      xs="12"
      md="2"
    >
      <v-text-field
        v-model="item.cost"
        label="进价11"
        class="purple-input"
      />
    </v-col>
    <v-col
      xs="12"
      md="2"
    >
      <v-text-field
        v-model="item.limitPrice"
        label="限价"
        class="purple-input"
      />
    </v-col>
    <v-col
      xs="12"
      md="2"
    >
      <v-text-field
        v-model="item.activityPrice"
        label="活动价"
        class="purple-input"
      />
    </v-col>
    <v-col
      xs="12"
      md="1"
      offset-md="1"
    >
      <v-btn
        v-if="this.index !== 0"
        icon
        @click="removeVariant"
      >
        <v-icon color="green">
          mdi-minus-circle-outline
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    item: {}
  }),
  methods: {
    removeVariant: function () {
      this.$emit('remove-variant', this.index)
    }
  }
}
</script>
