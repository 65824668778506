<template>
  <material-card
    color="green"
    title="产品时间段销售统计"
    text="按时间段"
  >
    <v-select
      v-model="productId"
      :items="products"
      label="产品"
      @change="productChange"
    />
    <v-data-table
      id="best_sales"
      :headers="headers"
      :items="items"
      disable-pagination
      hide-default-footer
    >
      <template
        slot="headerCell"
        slot-scope="{ header }"
      >
        <span
          class="font-weight-light text-warning text--darken-3"
          v-text="header.text"
        />
      </template>
    </v-data-table>
  </material-card>
</template>
<script>
export default {
  data () {
    return {
      products: [
        {
          text: '插座转换器',
          value: 42858368352
        },
        {
          text: '大功率一转二',
          value: 51901421054
        },
        {
          text: '大功率有线',
          value: 51869963875
        },
        {
          text: 'B7插座',
          value: 43559838032
        },
        {
          text: '胶带',
          value: 118885872175
        }
      ],
      productId: null,
      headers: [
        {
          sortable: false,
          text: '时间段',
          value: 'order_hour'
        },
        {
          sortable: false,
          text: '销量',
          value: 'c',
          align: 'right'
        }
      ],
      items: []
    }
  },
  mounted () {
    // this.getReport();
  },
  methods: {
    getReport: async function () {
      this.$http
        .get('/orders/timeperiod/report?id=' + this.productId)
        .then(response => {
          this.items = response.data
        })
    },
    productChange: function () {
      this.getReport()
    }
  }
}
</script>
