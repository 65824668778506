<template>
  <v-select
    :value="value"
    :items="channels"
    placeholder="渠道"
    clearable
    @input="emitValue"
  />
</template>
<script>
export default {
  props: { value: { type: Number, required: false, default: 2 } },
  data: () => ({
    channels: [
      {
        text: '微店',
        value: 1
      },
      {
        text: '拼多多',
        value: 2
      }
    ]
  }),
  methods: {
    emitValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
